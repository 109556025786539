import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HookEcho from './HookEcho';
import TheStartling from './TheStartling';
import Home from "./Home";
import AboutUs from "./AboutUs";
import NavBar from "./NavBar";
import Grid from '@mui/material/Grid';
import {Box, Container, Link, Typography} from "@mui/material";

function App() {
    return (
        <Router>
            <NavBar />

            <Box sx={{ width: '100%' }}>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/hookecho" element={<HookEcho />} />
                        <Route path="/thestartling" element={<TheStartling />} />
                        <Route path="/aboutus" element={<AboutUs />} />
                    </Routes>
                </Grid>
            </Box>
        </Router>
    );
}

export default App;