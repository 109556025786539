function TheStartlingDesc() {
  return (
      <p>
          We are excited to announce The Startling. Are you brave enough to set foot in The Startling woods?
          Prepare to experience classic horror in an interactive story format,
          where your choices will help determine the outcome.
      </p>
  );
}

export default TheStartlingDesc;