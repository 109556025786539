import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function CopyIcon() {
    const [tooltipText, setTooltipText] = useState('Copy email');

    const handleCopy = () => {
        navigator.clipboard.writeText('tyler@grewlergames.com');
        setTooltipText('Copied!');
        setTimeout(() => setTooltipText('Copy email'), 2000);
    };

    return (
        <Tooltip title={tooltipText}>
            <IconButton onClick={handleCopy}>
                <ContentCopyIcon style={{ color: 'white' }} />
            </IconButton>
        </Tooltip>
    );
}

export default CopyIcon;