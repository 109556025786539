import {Box, Container, Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import React from "react";
import TheStartlingDesc from "./TheStartlingDesc";
import HookEchoDesc from "./HookEchoDesc";
import Grid from '@mui/material/Grid';

function Home() {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h2" gutterBottom>
                    Grewler Games
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We are an indie development studio currently developing The Startling, our first game set to release in October 2025.
                </Typography>

                <Typography variant="h4" gutterBottom style={{ marginTop: '4rem' }}>
                    Explore our upcoming games
                </Typography>
            </Grid>

          <Grid item xs={12}>
            <Link component={RouterLink} to="/thestartling" variant="h6">
                The Startling
            </Link>
          </Grid>
          <Grid item xs={12}>
              <img
                alt="The Startling"
                src="images/startling_screenshot.png"
                style={{ height: "50vh", width: "50vh" }}
              />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <TheStartlingDesc/>
          </Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={12}>
                <Link component={RouterLink} to="/hookecho" variant="h6">
                    Hook Echo
                </Link>
            </Grid>
            <Grid item xs={12}>
                <img src="images/supercell.jpg" alt="Hook Echo Supercell" style={{ height: "50vh", width: "50vh" }}/>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
                <HookEchoDesc/>
            </Grid>
            <Grid item xs={2}></Grid>
    </>)
}

export default Home;