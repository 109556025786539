import React from 'react';
import './App.css';
import { Container, Typography, Box } from '@mui/material';
import HookEchoDesc from "./HookEchoDesc";
import NavBar from "./NavBar";
import Grid from '@mui/material/Grid';

function HookEcho() {
    return (
    <>
        <Grid item xs={12}>
            <Typography variant="h4" component="h1" gutterBottom>
                Hook Echo
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
                <HookEchoDesc/>
            </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
            <img src="images/supercell.jpg" alt="Hook Echo Supercell" style={{ height: "50vh", width: "50vh" }}/>
        </Grid>
        <Grid item xs={2}></Grid>
    </>

    );
}

export default HookEcho;