import React from "react";
import './App.css';
import NavBar from "./NavBar";
import {Box, Container, Link, Typography} from "@mui/material";
import CopyIcon from "./CopyIcon";
import Grid from '@mui/material/Grid';

function AboutUs() {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Grewler Games
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" mb={3}>
                    We are a small indie game development studio based out of Des Moines, Iowa. We love horror games and movies and are always looking for inspiration.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Contact us
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" mb={3}>
                    Email: <Link href="mailto:tyler@grewlergames.com" style={{ color: '#1E90FF !important' }}>tyler@grewlergames.com</Link> <CopyIcon />
                </Typography>
            </Grid>
        </>
    )
}

export default AboutUs;